@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body * {
    font-family: Lato, sans-serif;
    box-sizing: border-box;
}

.App {
    text-align: center;
    padding: 1rem;
}

input[type="text"] {
    width: 100%;
    display: block;
    padding: 6px;
    font-size: 14px;
    margin: 0 auto 1rem;
    border: 1px solid rgba(27, 31, 36, .2);
    border-radius: 6px;
}

button {
    display: block;
    margin: 0 auto 1rem;
    color: #505050;
    font-weight: 700;
    font-size: 14px;
    background: rgb(246, 248, 250);
    padding: 5px 0;
    border: 1px solid rgba(27, 31, 36, .15);
    border-radius: 6px;
    height: 2rem;
    width: 14rem;
    cursor: pointer;
}

button:hover {
    border: 1px solid #707070;
}

button.red {
    color: rgb(207, 34, 46);
    border: 1px solid rgb(207, 34, 46);
}

button.red:hover {
    background: rgb(207, 34, 46);
    color: rgb(246, 248, 250);
}